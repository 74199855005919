export enum FEEDBACK_CAMPAIGN_TYPES {
  POPUP = 'POPUP',
  DIALOG = 'DIALOG',
}

export enum FEEDBACK_CAMPAIGN_ATTACHED_ELEMENTS {
  METEO = 'METEO',
  SUGGESTIONS = 'SUGGESTIONS',
  FEEDBACK = 'FEEDBACK',
  CREATE_FLASHCARDS_DECK = 'CREATE_FLASHCARDS_DECK',
}

export enum FEEDBACK_CAMPAIGN_USER_INTERACTION_TYPES {
  SHOWUP = 'SHOWUP',
  CLICK = 'CLICK',
}
