export type FileUploadContextRules = {
  maxSize?: number;
  mimeTypes?: string[];
  maxFiles?: number;
};

// Possible contexts for file uploads.
export enum FILE_UPLOAD_CONTEXTS {
  ASSIGNMENT_ATTACHMENT = 'ASSIGNMENT_ATTACHMENT',
  ASSIGNMENT_SUBMISSION_ATTACHMENT = 'ASSIGNMENT_SUBMISSION_ATTACHMENT',
  ASSIGNMENT_SUBMISSION_CORRECTION = 'ASSIGNMENT_SUBMISSION_CORRECTION',
  USER_PROFILE_PICTURE = 'USER_PROFILE_PICTURE',
  LESSON_RESOURCE_THUMBNAIL = 'LESSON_RESOURCE_THUMBNAIL',
  LESSON_THUMBNAIL = 'LESSON_THUMBNAIL',
  NEWS_PICTURE = 'NEWS_PICTURE',
  NEWS_ATTACHMENTS = 'NEWS_ATTACHMENTS',
  VIRTUAL_CLASSROOM_THUMBNAIL = 'VIRTUAL_CLASSROOM_THUMBNAIL',
  VIRTUAL_CLASSROOM_ATTACHMENT = 'VIRTUAL_CLASSROOM_ATTACHMENT',
  EDITOR_ATTACHMENT = 'EDITOR_ATTACHMENT',
  EDITOR_CAROUSEL = 'EDITOR_CAROUSEL',
  EDITOR_AUDIO = 'EDITOR_AUDIO',
  MCQ_QUESTION_IMAGE = 'MCQ_QUESTION_IMAGE',
  MCQ_QUESTION_AUDIO = 'MCQ_QUESTION_AUDIO',
  STUDENT_CARD_PICTURE = 'STUDENT_CARD_PICTURE',
}

export const FILE_UPLOAD_CONTEXT_RULES: Record<
  FILE_UPLOAD_CONTEXTS,
  FileUploadContextRules
> = {
  [FILE_UPLOAD_CONTEXTS.ASSIGNMENT_ATTACHMENT]: {
    maxSize: 30000000, // 30Mb.
    mimeTypes: [
      'image/jpeg',
      'application/pdf',
      'application/x-cfb', // .doc
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-powerpoint', // .ppt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
      'audio/mpeg', // .mp3
      'audio/MPA', // .mp3
      'audio/mpa-robust', // .mp3
      'audio/wav', // .wav
      'audio/vnd.wave', // .wav
      'audio/x-wav', // .wav
      'audio/wave', // .wav
    ],
  },
  [FILE_UPLOAD_CONTEXTS.ASSIGNMENT_SUBMISSION_ATTACHMENT]: {
    maxSize: 10 * 1024 * 1024,
    mimeTypes: [
      'application/x-cfb', // .doc
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-powerpoint', // .ppt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
      'audio/mpeg', // .mp3
      'audio/MPA', // .mp3
      'audio/mpa-robust', // .mp3
      'audio/wav', // .wav
      'audio/vnd.wave', // .wav
      'audio/x-wav', // .wav
      'audio/wave', // .wav
      'audio/mp4', // .mp4
      'video/mp4', // .mp4
      'video/x-msvideo', // .avi
      'video/vnd.avi', // .avi
      'video/avi', // .avi
      'video/msvideo', // .avi
      'video/quicktime', // .mov
    ],
    maxFiles: 10,
  },
  [FILE_UPLOAD_CONTEXTS.ASSIGNMENT_SUBMISSION_CORRECTION]: {
    maxSize: 10 * 1024 * 1024,
    mimeTypes: [
      'image/jpeg',
      'application/pdf',
      'application/x-cfb', // .doc
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-powerpoint', // .ppt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
      'audio/mpeg', // .mp3
      'audio/MPA', // .mp3
      'audio/mpa-robust', // .mp3
      'audio/wav', // .wav
      'audio/vnd.wave', // .wav
      'audio/x-wav', // .wav
      'audio/wave', // .wav
    ],
  },
  [FILE_UPLOAD_CONTEXTS.USER_PROFILE_PICTURE]: {
    maxSize: 3 * 1024 * 1024,
    mimeTypes: ['image/jpeg', 'image/png'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.LESSON_RESOURCE_THUMBNAIL]: {
    maxSize: 2 * 1024 * 1024,
    mimeTypes: ['image/jpeg', 'image/png'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.LESSON_THUMBNAIL]: {
    maxSize: 2 * 1024 * 1024,
    mimeTypes: ['image/jpeg', 'image/png'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.NEWS_PICTURE]: {
    maxSize: 2 * 1024 * 1024,
    mimeTypes: ['image/jpeg', 'image/png'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.NEWS_ATTACHMENTS]: {
    maxSize: 3 * 1024 * 1024,
    mimeTypes: [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/zip',
      'application/x-cfb', // .doc
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    maxFiles: 3,
  },
  [FILE_UPLOAD_CONTEXTS.EDITOR_ATTACHMENT]: {
    maxSize: 30000000,
    mimeTypes: [
      'application/pdf',
      'application/zip',
      'application/x-cfb', // .doc
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.VIRTUAL_CLASSROOM_THUMBNAIL]: {
    maxSize: 2 * 1024 * 1024,
    mimeTypes: ['image/jpeg', 'image/png'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.VIRTUAL_CLASSROOM_ATTACHMENT]: {
    maxSize: 30 * 1024 * 1024,
    mimeTypes: [
      'image/jpeg',
      'application/pdf',
      'application/zip',
      'application/x-cfb', // .doc
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  },
  [FILE_UPLOAD_CONTEXTS.EDITOR_CAROUSEL]: {
    maxSize: 2 * 1024 * 1024,
    mimeTypes: ['image/jpeg', 'image/png'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.EDITOR_AUDIO]: {
    maxSize: 5 * 1024 * 1024,
    mimeTypes: ['audio/mpeg'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.MCQ_QUESTION_IMAGE]: {
    maxSize: 2 * 1024 * 1024,
    mimeTypes: ['image/png', 'image/jpeg'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.MCQ_QUESTION_AUDIO]: {
    maxSize: 5 * 1024 * 1024,
    mimeTypes: ['audio/mpeg'],
    maxFiles: 1,
  },
  [FILE_UPLOAD_CONTEXTS.STUDENT_CARD_PICTURE]: {
    maxSize: 3 * 1024 * 1024,
    mimeTypes: ['image/jpeg', 'image/png'],
    maxFiles: 1,
  },
};

export enum FILE_UPLOAD_SERVE_TYPES {
  STORAGE = 'STORAGE',
  CDN = 'CDN',
}

export const FILE_UPLOAD_CONTEXT_SERVE_TYPES: Record<
  FILE_UPLOAD_CONTEXTS,
  FILE_UPLOAD_SERVE_TYPES
> = {
  [FILE_UPLOAD_CONTEXTS.ASSIGNMENT_ATTACHMENT]: FILE_UPLOAD_SERVE_TYPES.STORAGE,
  [FILE_UPLOAD_CONTEXTS.ASSIGNMENT_SUBMISSION_ATTACHMENT]:
    FILE_UPLOAD_SERVE_TYPES.STORAGE,
  [FILE_UPLOAD_CONTEXTS.ASSIGNMENT_SUBMISSION_CORRECTION]:
    FILE_UPLOAD_SERVE_TYPES.STORAGE,
  [FILE_UPLOAD_CONTEXTS.USER_PROFILE_PICTURE]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.LESSON_RESOURCE_THUMBNAIL]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.LESSON_THUMBNAIL]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.NEWS_PICTURE]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.NEWS_ATTACHMENTS]: FILE_UPLOAD_SERVE_TYPES.STORAGE,
  [FILE_UPLOAD_CONTEXTS.EDITOR_ATTACHMENT]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.EDITOR_CAROUSEL]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.EDITOR_AUDIO]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.VIRTUAL_CLASSROOM_THUMBNAIL]:
    FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.VIRTUAL_CLASSROOM_ATTACHMENT]:
    FILE_UPLOAD_SERVE_TYPES.STORAGE,
  [FILE_UPLOAD_CONTEXTS.MCQ_QUESTION_IMAGE]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.MCQ_QUESTION_AUDIO]: FILE_UPLOAD_SERVE_TYPES.CDN,
  [FILE_UPLOAD_CONTEXTS.STUDENT_CARD_PICTURE]: FILE_UPLOAD_SERVE_TYPES.CDN,
};

// If an upload context does not define a max file size, default to 5Mb (totally arbitrary).
export const FILE_UPLOAD_DEFAULT_MAX_SIZE = 5 * 1024 * 1024;

export const DATA_CORRECTION_FILE_BUCKET_FOLDER = 'data-corrections';

export const PENDING_DATA_CORRECTION_FILE_BUCKET_FOLDER = 'pending';

export const COMPLETED_DATA_CORRECTION_FILE_BUCKET_FOLDER = 'done';

export const STUDENT_CARD_THUMBNAIL_ASPECT_RATIO = 35 / 45;
