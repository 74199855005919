import {
  browserTracingIntegration,
  type EventHint,
  init as initSentry,
  replayIntegration,
} from '@sentry/react';

import { GLOBAL_ERRORS } from '@eversity/domain/constants';
import { isResponseError } from '@eversity/types/web';
import { type ApiErrorResponse } from '@eversity/utils/express';

import config from '../../config/env';

export const bootstrapSentry = () => {
  const ignoreAgents = [
    'GoogleHC/1.0',
    'Elastic-Heartbeat/7.17.5',
    'python-requests/2.28.1',
  ];

  if (config.sentry.enabled) {
    initSentry({
      dsn: config.sentry.dsn,
      environment: config.sentry.env,
      release: config.sentry.release,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: config.sentry.env === 'prod' ? 1.0 : 0.0,
      integrations: [
        browserTracingIntegration(),
        replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: config.sentry.env === 'prod' ? 1.0 : 0.0,
      tracesSampler: (samplingContext) => {
        if (
          ignoreAgents.includes(samplingContext?.request?.headers['user-agent'])
        )
          return false;
        return 0.5;
      },
      beforeSend(event, hint: EventHint) {
        const error = hint.originalException as Error;

        if (isResponseError(error)) {
          if (
            error.status === 401 &&
            error.response.body &&
            (error.response.body as ApiErrorResponse).message ===
              GLOBAL_ERRORS.USER_NOT_AUTHENTICATED.MESSAGE
          ) {
            return null;
          }
        }
        return event;
      },
    });
  }
};
