import { css, type Theme } from '@emotion/react';

import { ALERT_VARIANTS } from './constants';

export const container = (theme: Theme) => css`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;

  &.${ALERT_VARIANTS.INFO} {
    background-color: ${theme.colors.primary[25]};
  }

  &.${ALERT_VARIANTS.WARNING} {
    background-color: ${theme.colors.warning[25]};
  }

  &.${ALERT_VARIANTS.DANGER} {
    background-color: ${theme.colors.danger[25]};
  }

  &.${ALERT_VARIANTS.SUCCESS} {
    background-color: ${theme.colors.success[25]};
  }
`;

export const content = css`
  flex: 1;
`;
