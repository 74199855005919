import { css } from '@emotion/react';

export const container = css`
  position: relative;
`;

export const headSvg = (headPicture: string) => css`
  position: absolute;
  z-index: -1;
  top: -45px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${headPicture});
  width: 85px;
  height: 115px;
`;

export const leftHandSvg = (leftHandPicture: string) => css`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: -13px;
  background-image: url(${leftHandPicture});
  width: 26px;
  height: 43px;
  z-index: 1;
`;

export const rightHandSvg = (rightHandPicture: string) => css`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -12px;
  background-image: url(${rightHandPicture});
  width: 24px;
  height: 43px;
  z-index: 1;
`;
