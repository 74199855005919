import { css, type Theme } from '@emotion/react';

const TRACK_HEIGHT = 4;
const THUMB_RADIUS = 10;

export const slider = (theme: Theme, isEmpty: boolean, isFull: boolean) => css`
  width: 100%;

  .track-0 {
    ${isEmpty && 'display: none'};

    position: relative;
    background: ${theme.colors.primary[500]};

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    ${isFull && 'border-top-right-radius: 4px;'}
    ${isFull && 'border-bottom-right-radius: 4px;'}
    height: ${TRACK_HEIGHT}px;
    transform: translateY(-50%);
  }

  .track-1 {
    ${isFull && 'display: none'};

    position: relative;
    background: ${theme.colors.gray[50]};

    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    ${isEmpty && 'border-top-left-radius: 4px'};
    ${isEmpty && 'border-bottom-left-radius: 4px'};
    height: ${TRACK_HEIGHT}px;
    transform: translateY(-50%);
  }

  .thumb {
    background-color: ${theme.colors.primary[500]};

    height: ${THUMB_RADIUS}px;
    width: ${THUMB_RADIUS}px;

    cursor: pointer;
    border-radius: 50%;

    transform: translateY(-50%);
  }

  .thumb.active {
    outline: none;
  }
`;
