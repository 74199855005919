import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { FLASH_CARD_HEIGHT, FLASH_CARD_WIDTH } from '../constants';

export const flashCardContainer = css`
  display: flex;
  flex-direction: column;

  position: relative;

  width: ${FLASH_CARD_WIDTH}px;
  height: ${FLASH_CARD_HEIGHT}px;
`;

export const card = (theme: Theme) => css`
  position: absolute;
  height: 100%;
  width: 100%;

  transition: ${theme.transitions.default()};
  transform-style: preserve-3d;

  border-radius: 8px;
  box-shadow: 0 0 5px 1px
    ${new TinyColor(theme.colors.gray[500]).setAlpha(0.15).toRgbString()};

  &.frontDisplayed {
    transform: rotateY(0);
  }

  &.backDisplayed {
    transform: rotateY(180deg);
  }
`;

export const cardSide = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  width: 100%;
  height: 100%;

  backface-visibility: hidden;

  border-radius: 8px;
  padding: 20px;

  &.frontSide {
    background-color: ${theme.colors.gray[0]};
  }

  &.backSide {
    color: ${theme.colors.gray[0]};
    background-color: ${theme.colors.primary[500]};
    transform: rotateY(-180deg);
  }
`;
