import { memo, useContext } from 'react';
import { noop } from 'lodash';

import { Typography } from '@eversity/ui/design-system';
import { Truncate } from '@eversity/ui/utils';
import { formatTimeForAudio } from '@eversity/utils/misc';

import AudioDefaultThumbnail from '../audio-default-thumbnail/AudioDefaultThumbnail';
import { DISPLAY_SMALL_CLASSNAME, HIDE_SMALL_CLASSNAME } from '../constants';
import { AudioPlayerContext } from '../contexts/AudioPlayer.context';
import * as styles from './AudioSummary.styles';

type AudioSummaryProps = {
  title: string;
  subtitle: string;
  onClickTitle?: () => void;
  thumbnailHref?: string;
};

const getTime = (audioCurrentTime: number, audioDuration: number) => {
  return `${audioCurrentTime !== -1 ? formatTimeForAudio(audioCurrentTime) : '--:--'} / ${audioDuration !== -1 ? formatTimeForAudio(audioDuration) : '--:--'}`;
};

const AudioSummary = ({
  thumbnailHref,
  title,
  subtitle,
  onClickTitle = noop,
}: AudioSummaryProps) => {
  const { audioDuration, audioCurrentTime, isMobileView, onClickPlayer } =
    useContext(AudioPlayerContext);

  return (
    <button
      css={styles.mainContainer}
      onClick={isMobileView ? onClickPlayer : onClickTitle}
      type="button"
    >
      <div css={styles.audioInfos}>
        {thumbnailHref ? (
          <img
            alt="Audio thumbnail"
            src={thumbnailHref}
            css={styles.thumbnail}
          />
        ) : (
          <AudioDefaultThumbnail />
        )}

        <div css={styles.audioTitles}>
          {isMobileView ? (
            <Truncate maxLines={1}>
              <Typography variant={Typography.VARIANTS.BUTTON_SMALL_BOLD}>
                {title}
              </Typography>
            </Truncate>
          ) : (
            <div css={styles.title}>
              <Truncate maxLines={2}>
                <Typography
                  element="p"
                  variant={Typography.VARIANTS.BUTTON_SMALL_BOLD}
                >
                  {title}
                </Typography>
              </Truncate>
            </div>
          )}

          <Typography
            element="p"
            variant={Typography.VARIANTS.BUTTON_SMALL_REGULAR}
            className={HIDE_SMALL_CLASSNAME}
          >
            {subtitle}
          </Typography>

          <Typography
            element="p"
            variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
            className={DISPLAY_SMALL_CLASSNAME}
          >
            {getTime(audioCurrentTime, audioDuration)}
          </Typography>
        </div>
      </div>

      <Typography
        variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
        className={HIDE_SMALL_CLASSNAME}
      >
        {getTime(audioCurrentTime, audioDuration)}
      </Typography>
    </button>
  );
};

export default memo(AudioSummary);
