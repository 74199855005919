import { cloneElement, memo, type ReactElement, type ReactNode } from 'react';
import { Collapse } from 'react-collapse';
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Attention, CheckOne, Close, CloseOne, Info } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';

import { globalMessages } from '@eversity/ui/intl';

import { COLOR_GROUPS } from '../../../config/colors/constants';
import { Button } from '../../general/button/Button';
import { ICON_SIZES } from '../../general/icon/constants';
import { Typography } from '../../general/typography/Typography';
import * as styles from './Alert.styles';
import { ALERT_VARIANTS } from './constants';

const ALERT_ICON_MAPPING: Record<ALERT_VARIANTS, ReactElement> = {
  [ALERT_VARIANTS.INFO]: <Info />,
  [ALERT_VARIANTS.DANGER]: <CloseOne />,
  [ALERT_VARIANTS.WARNING]: <Attention />,
  [ALERT_VARIANTS.SUCCESS]: <CheckOne />,
};

const ALERT_ICON_COLOR_GROUP_MAPPING: Record<ALERT_VARIANTS, COLOR_GROUPS> = {
  [ALERT_VARIANTS.INFO]: COLOR_GROUPS.PRIMARY,
  [ALERT_VARIANTS.DANGER]: COLOR_GROUPS.DANGER,
  [ALERT_VARIANTS.WARNING]: COLOR_GROUPS.WARNING,
  [ALERT_VARIANTS.SUCCESS]: COLOR_GROUPS.SUCCESS,
};

export type AlertProps = {
  /** Variant of the Alert component. */
  variant: `${ALERT_VARIANTS}`;
  /** Whether the alert is open or not. */
  isOpen?: boolean;
  /** Callback to close the alert. */
  onRequestClose?: () => void;
  /** Content displayed. */
  children: ReactNode;
  /** Css class name. */
  className?: string;
};

export const AlertBase = ({
  variant,
  isOpen = true,
  onRequestClose = noop,
  children,
  className = null,
}: AlertProps) => {
  const theme = useTheme();
  const intl = useIntl();

  const colorGroup = ALERT_ICON_COLOR_GROUP_MAPPING[variant];
  return (
    <Collapse isOpened={isOpen}>
      <div
        css={styles.container}
        className={cn(variant, className)}
      >
        {cloneElement(ALERT_ICON_MAPPING[variant], {
          size: ICON_SIZES.MEDIUM,
          fill: [theme.colors[colorGroup][500], theme.colors[colorGroup][50]],
        })}
        <Typography
          variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
          css={styles.content}
        >
          {children}
        </Typography>
        <Button
          size={Button.SIZES.SMALL}
          onClick={onRequestClose}
          icon={<Close />}
          aria-label={intl.formatMessage(globalMessages.CLOSE)}
          data-cy="dialog-close-button"
        />
      </div>
    </Collapse>
  );
};

AlertBase.displayName = 'Alert';

export const Alert = memo(AlertBase);
