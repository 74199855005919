import {
  Fragment,
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  FEEDBACK_CAMPAIGN_TYPES,
  FEEDBACK_CAMPAIGN_USER_INTERACTION_TYPES,
} from '@eversity/domain/constants';
import dayjs from '@eversity/services/dayjs';
import { useBoolState } from '@eversity/ui/utils';

import { useTypeformCallbacks } from '../../../../../domain/feedbacks/typeform/events';
import { useAuth } from '../../../../context/auth-context';
import { useUserLastVisitedCourse } from '../../../../hooks/local-storage/user';
import { useStudentFeedbackCampaigns } from '../../../../hooks/queries/user';
import { useUserCourse } from '../../../../hooks/useUserCourse';
import { FeedbackTypeformDialog } from '../../typeform/dialog/FeedbackTypeformDialog';
import { FeedbackTypeformPopup } from '../../typeform/popup/FeedbackTypeformPopup';

export type FeedbackCampaignsManagerProps = {
  children?: ReactNode;
};

export const FeedbackCampaignsManagerBase = ({
  children = null,
}: FeedbackCampaignsManagerProps) => {
  const { courseId, classId } = useParams();
  const [isOpen, onOpen, onClose] = useBoolState(false);

  // Ensure that the user has the student feedback campaigns loaded.
  const { data: campaigns } = useStudentFeedbackCampaigns(courseId, classId);

  const showupCampaign = (campaigns || []).find(
    (campaign) =>
      campaign.userInteractionType ===
      FEEDBACK_CAMPAIGN_USER_INTERACTION_TYPES.SHOWUP,
  );
  const { user } = useAuth();
  const [lastVisitedCourse] = useUserLastVisitedCourse();
  const { currentStudentCourse } = useUserCourse(
    lastVisitedCourse?.courseId,
    lastVisitedCourse?.classId,
  );

  useEffect(() => {
    if (showupCampaign) {
      const minOpenCampaignDate = dayjs().add(
        showupCampaign.timeBeforeRequest,
        'minutes',
      );

      const timeoutHandle = setTimeout(
        () => onOpen(),
        // Open the dialog 5 minutes after the app is opened.
        dayjs(minOpenCampaignDate).diff(dayjs(), 'ms'),
      );

      return () => clearTimeout(timeoutHandle);
    }

    return undefined;
  }, [showupCampaign, onOpen]);

  const { onSubmitTypeform, onCloseTypeform } =
    useTypeformCallbacks(showupCampaign);

  const onCloseDialog = useCallback(() => {
    onClose();
    onCloseTypeform();
  }, [onClose, onCloseTypeform]);

  const shouldDisplayShowupCampaign = useMemo(
    () => !!user && !!currentStudentCourse && !!showupCampaign,
    [user, currentStudentCourse, showupCampaign],
  );

  if (!shouldDisplayShowupCampaign) return children;

  return (
    <Fragment>
      {children}

      {showupCampaign.type === FEEDBACK_CAMPAIGN_TYPES.DIALOG ? (
        <FeedbackTypeformDialog
          key={showupCampaign.attachedElement}
          isOpen={isOpen}
          feedbackCampaign={showupCampaign}
          onRequestClose={onCloseDialog}
          onAskLater={onCloseDialog}
          onSubmit={onSubmitTypeform}
          showIntro
        />
      ) : (
        <FeedbackTypeformPopup
          key={showupCampaign.attachedElement}
          isOpen={isOpen}
          feedbackCampaign={showupCampaign}
          onRequestClose={onCloseDialog}
          onSubmit={onSubmitTypeform}
        />
      )}
    </Fragment>
  );
};

FeedbackCampaignsManagerBase.displayName = 'FeedbackCampaignsManager';

export const FeedbackCampaignsManager = memo(FeedbackCampaignsManagerBase);
