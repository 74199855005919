import { css, type Theme } from '@emotion/react';

import { removeButtonAppearance } from '@eversity/ui/design-system';

import { HIDE_DRAWER_CLASSNAME } from '../constants';

export const mainContainer = (theme: Theme) => css`
  height: 100%;
  width: 100%;

  padding: 20px;

  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  z-index: 100;
  background-color: ${theme.colors.gray[0]};

  & .${HIDE_DRAWER_CLASSNAME} {
    display: none;
  }

  &:not(.isDrawerOpen) {
    display: none;

    transform: translateY(100%);
    transition: ${theme.transitions.default('transform')};
  }

  &.isDrawerOpen {
    display: flex;
    flex-direction: column;

    transform: translateY(0);
    transition: ${theme.transitions.default('transform')};
  }
`;

export const logo = css`
  display: block;
`;

export const brand = (theme: Theme) => css`
  color: ${theme.colors.primary[500]};
`;

export const logoContainer = (theme: Theme) => css`
  display: flex;
  align-items: center;

  gap: 10px;
  color: ${theme.colors.gray[900]};
`;

export const drawerHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const audioThumbnail = css`
  width: 100%;
  height: 120px;
  object-fit: cover;
`;

export const drawerBody = css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &:not(.isPortrait) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  height: 100%;
  padding: 50px 20px;

  & > *:nth-of-type(2) > *:first-of-type {
    gap: 20px;
  }
`;

export const audioInfos = css`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

export const audioTitles = css`
  ${removeButtonAppearance};

  display: flex;
  flex-direction: column;
`;
