import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactElement,
} from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

import { COLOR_GROUPS } from '../../../config/colors/constants';
import { ICON_SHADOW_SIZES, ICON_SIZE_MAPPING } from './constants';
import * as styles from './IconShadow.styles';

export type IconShadowProps = {
  icon: ReactElement;
  colorGroup: COLOR_GROUPS;
  size?: ICON_SHADOW_SIZES;
  hasCircleBorder?: boolean;
};
export const IconShadowBase = ({
  icon,
  colorGroup,
  size = ICON_SHADOW_SIZES.MEDIUM,
  hasCircleBorder = false,
}: IconShadowProps) => {
  const theme = useTheme();

  return (
    /**
     * These divs display the icon. We need to add a box-shadow to the icon, but the viewbox
     * is larger than its content, so we display an empty div with the box-shadow, and render
     * the icon inside with absolute positioning to make it work visually.
     * Feel free to refactor if you have a better solution.
     */
    <div css={styles.iconContainer}>
      <div
        css={styles.iconPlaceholder(theme.colors[colorGroup][500])}
        className={size}
      >
        {!hasCircleBorder ? (
          <div css={styles.customIconContainer(theme.colors[colorGroup][500])}>
            {cloneElement(icon, {
              size: ICON_SIZE_MAPPING[size] / 2,
              fill: [
                theme.colors[colorGroup][500],
                theme.colors[colorGroup][50],
              ],
            })}
          </div>
        ) : (
          <div css={styles.icon}>
            {cloneElement(icon, {
              size: ICON_SIZE_MAPPING[size],
              fill: [theme.colors[colorGroup][500], theme.colors.gray[0]],
            })}
          </div>
        )}
      </div>
    </div>
  );
};

IconShadowBase.displayName = 'IconShadow';

IconShadowBase.propTypes = {
  /** Icon. */
  icon: PropTypes.node.isRequired,
  /** Color map from the theme (ex: theme.colors.primary). */
  colorGroup: PropTypes.oneOf(Object.values(COLOR_GROUPS)).isRequired,
  /** Consider that the icon already has a border (ex: CheckOne, CloseOne, etc.). */
  hasCircleBorder: PropTypes.bool,
};

export const IconShadow: MemoExoticComponent<typeof IconShadowBase> & {
  SIZES?: typeof ICON_SHADOW_SIZES;
} = memo(IconShadowBase);

IconShadow.SIZES = ICON_SHADOW_SIZES;
