import { memo, type PropsWithChildren } from 'react';

import HeadSvg from '../../../../assets/images/ornement-wrapper/head.raw.svg';
import LeftHandSvg from '../../../../assets/images/ornement-wrapper/left-hand.raw.svg';
import RightHandSvg from '../../../../assets/images/ornement-wrapper/right-hand.raw.svg';
import * as styles from './OrnementWrapper.styles';

const OrnementWrapperBase = ({ children }: PropsWithChildren) => {
  return (
    <div css={styles.container}>
      <div css={styles.headSvg(HeadSvg)} />
      <div css={styles.leftHandSvg(LeftHandSvg)} />
      <div css={styles.rightHandSvg(RightHandSvg)} />
      {children}
    </div>
  );
};

export const OrnementWrapper = memo(OrnementWrapperBase);
