import { defineMessages } from 'react-intl';

export const globalMessages = defineMessages({
  YES: { defaultMessage: 'Oui' },
  NO: { defaultMessage: 'Non' },
  OR: { defaultMessage: 'ou' },
  OK: { defaultMessage: 'OK' },
  CANCEL: { defaultMessage: 'Annuler' },
  LEAVE: { defaultMessage: 'Quitter' },
  CLOSE: { defaultMessage: 'Fermer' },
  FINISH: { defaultMessage: 'Terminer' },
  SUBMIT: { defaultMessage: 'Soumettre' },
  CONFIRM: { defaultMessage: 'Confirmer' },
  SAVE: { defaultMessage: 'Enregistrer' },
  DUPLICATE: { defaultMessage: 'Dupliquer' },
  CREATE: { defaultMessage: 'Créer' },
  SUCCESS: { defaultMessage: 'Succès' },
  BETA: { defaultMessage: 'Beta' },
  SEARCH: { defaultMessage: 'Rechercher' },
  ADD: { defaultMessage: 'Ajouter' },
  EDIT: { defaultMessage: 'Modifier' },
  DELETE: { defaultMessage: 'Supprimer' },
  NEXT: { defaultMessage: 'Suivant' },
  PREVIOUS: { defaultMessage: 'Précédent' },
  SEND: { defaultMessage: 'Envoyer' },
  VALIDATE: { defaultMessage: 'Valider' },
  IMPORT: { defaultMessage: 'Importer' },
  PERCENT: { defaultMessage: '{value}%' },
  NOT_AFFECTED: { defaultMessage: 'N/A' },
  HOURS_MINUTES: { defaultMessage: '{hours}h{minutes}m' },
  N_DAYS: { defaultMessage: '{count, plural, one {# jour} other {# jours}}' },
  KNOW_MORE: { defaultMessage: 'En savoir plus' },
  SEE_MORE: { defaultMessage: 'Voir plus' },
  COLLAPSE: { defaultMessage: 'Réduire' },
  DOWNLOAD: { defaultMessage: 'Télécharger' },
  PERIOD: { defaultMessage: 'du {fromDate} au {toDate}' },
  COUNT_ON_TOTAL: { defaultMessage: '{count}/{total, number}' },

  UNEXPECTED_ERROR: { defaultMessage: 'Une erreur inattendue est survenue.' },
  UNEXPECTED_ERROR_MESSAGE: {
    defaultMessage: 'Une erreur inattendue est survenue : {message}.',
  },
} as const);
