import { css, type Theme } from '@emotion/react';

import {
  removeButtonAppearance,
  removeLinkAppearance,
} from '../../../../utils/style';
import { VARIANTS } from '../constants';

export const container = (theme: Theme, variant: VARIANTS) => css`
  ${removeLinkAppearance};
  ${removeButtonAppearance};

  height: 100%;
  width: ${variant === VARIANTS.HORIZONTAL ? '100%' : 'auto'};

  color: ${theme.colors.gray[700]};

  transition: ${theme.transitions.default()};

  &.isHidden {
    display: none;
  }

  &.isDisabled {
    cursor: default;
  }
`;
