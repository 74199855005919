import { defineMessages } from 'react-intl';

export const controlsTooltipsMessages = defineMessages({
  CLOSE_AUDIO_PLAYER: {
    defaultMessage: 'Fermer le lecteur',
  },

  PLAY: {
    defaultMessage: 'Lire',
  },

  PAUSE: {
    defaultMessage: 'Pause',
  },

  NEXT_TRACK: {
    defaultMessage: 'Piste suivante',
  },

  PREVIOUS_TRACK: {
    defaultMessage: 'Piste précédente',
  },

  NEXT_TRACKLIST: {
    defaultMessage: 'Liste suivante',
  },

  PREVIOUS_TRACKLIST: {
    defaultMessage: 'Liste précédente',
  },
});
