import { css, type Theme } from '@emotion/react';

export const container = (theme: Theme) => css`
  @media (min-width: ${theme.breakpoints.small}) {
    width: 48px;
    height: 48px;
  }

  height: 37px;
  width: 37px;

  background-color: ${theme.colors.primary[25]};
`;
