import { type AudioPlayerContextProviderProps } from './AudioPlayer.context';

export const findTracklistIndex = (
  tracklists: AudioPlayerContextProviderProps['tracklists'],
  tracklistId: string,
) => tracklists.findIndex(({ id }) => id === tracklistId) ?? 0;

export const findTrackIndex = (
  tracklists: AudioPlayerContextProviderProps['tracklists'],
  tracklistId: string,
  trackId: string,
) =>
  tracklists
    .find(({ id }) => id === tracklistId)
    ?.tracks.findIndex(({ id }) => id === trackId) ?? 0;
