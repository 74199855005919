import { css } from '@emotion/react';

import { VARIANTS } from './constants';

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  &.${VARIANTS.HORIZONTAL} {
    flex-direction: row;
    width: 100%;
  }
`;

export const step = css`
  display: flex;
  flex-direction: column;

  flex: 1;

  &.${VARIANTS.HORIZONTAL} {
    flex-direction: row;
  }

  &.isHidden {
    display: none;
  }
`;
