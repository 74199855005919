import { Fragment, memo, useCallback, useContext, useState } from 'react';
import Draggable, { type DraggableProps } from 'react-draggable';
import cn from 'classnames';
import { noop } from 'lodash';

import AudioControls, {
  type AudioControlProps,
} from './audio-controls/AudioControls';
import AudioDrawer from './audio-drawer/AudioDrawer';
import AudioSummary from './audio-summary/AudioSummary';
import * as styles from './AudioPlayer.styles';
import { DRAGGABLE_CLASSNAME } from './constants';
import { AudioPlayerContext } from './contexts/AudioPlayer.context';

export type AudioPlayerProps = {
  isOpen: boolean;
  thumbnailHref?: string;
  title: string;
  subtitle?: string;
  onClickTitle?: () => void;
} & Omit<
  AudioControlProps,
  'onNextTrack' | 'onPreviousTrack' | 'onNextTracklist' | 'onPreviousTracklist'
>;

// TODO: there is a deprecated warning on findDOMNode
// Please see https://github.com/react-grid-layout/react-draggable/issues/693

export const AudioPlayerBase = ({
  thumbnailHref = null,
  title,
  subtitle = null,
  isOpen = false,
  onClickTitle = noop,
  isLoading = false,
  ...props
}: AudioPlayerProps) => {
  const { audioRef, isMobileView, currentTrack, isFileLoading } =
    useContext(AudioPlayerContext);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const onDrag: DraggableProps['onDrag'] = useCallback((_, { x, y }) => {
    setPosition({ x, y });
  }, []);

  return (
    <Fragment>
      <Draggable
        bounds="body"
        onDrag={onDrag}
        disabled={isMobileView}
        position={position}
        handle={`.${DRAGGABLE_CLASSNAME}`}
      >
        <div
          css={styles.mainContainer}
          className={cn({ isOpen })}
        >
          <AudioSummary
            thumbnailHref={thumbnailHref}
            title={title}
            subtitle={subtitle}
            onClickTitle={onClickTitle}
          />

          <AudioControls
            {...props}
            isLoading={isLoading || isFileLoading}
          />

          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio
            ref={audioRef}
            src={currentTrack?.href}
            controls
            hidden
          />
        </div>
      </Draggable>

      <AudioDrawer
        thumbnailHref={thumbnailHref}
        title={title}
        subtitle={subtitle}
        onClickTitle={onClickTitle}
        isLoading={isLoading || isFileLoading}
      />
    </Fragment>
  );
};

export const AudioPlayer = memo(AudioPlayerBase);
