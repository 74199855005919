import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import {
  AUDIO_PLAYER_HEIGHT,
  AUDIO_PLAYER_WIDTH,
  DISPLAY_SMALL_CLASSNAME,
  HIDE_SMALL_CLASSNAME,
} from './constants';

export const mainContainer = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: default;

  &:not(.isOpen) {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.small}) {
    height: 57px;
    // Set max-width to calc(100% - 20px) to account for 10px margins on both sides
    width: calc(100% - 20px);

    right: 10px;
    bottom: 20px;
    padding: 10px;

    & .${HIDE_SMALL_CLASSNAME} {
      display: none;
    }
  }

  @media (min-width: ${theme.breakpoints.small}) {
    & .${DISPLAY_SMALL_CLASSNAME} {
      display: none;
    }
  }

  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 8px;

  background-color: ${theme.colors.gray[0]};

  width: ${AUDIO_PLAYER_WIDTH}px;
  height: ${AUDIO_PLAYER_HEIGHT}px;
  padding: 10px 10px 6px 10px;

  box-shadow:
    0px 2px 4px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()},
    0px 0px 11px 1px
      ${new TinyColor(theme.colors.gray[900]).setAlpha(0.1).toRgbString()};

  position: absolute;
  bottom: 10px;
  right: 10px;

  z-index: 5;
`;
