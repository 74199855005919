import { memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { HandLeft } from '@icon-park/react';
import { noop } from 'lodash';

import { Button, Typography } from '@eversity/ui/design-system';

import messages from './FlashCard.messages';
import * as styles from './FlashCard.styles';

export type FlashCardProps = {
  frontContent: ReactNode;
  backContent: ReactNode;
  displayedSide?: 'front' | 'back';
  onChangeDisplayedSide?: () => void;
};

export const FlashCardBase = ({
  frontContent,
  backContent,
  displayedSide = 'front',
  onChangeDisplayedSide = noop,
}: FlashCardProps) => {
  return (
    <div css={styles.flashCardContainer}>
      <div
        css={styles.card}
        className={`${displayedSide}Displayed`}
      >
        <div
          css={styles.cardSide}
          className="frontSide"
        >
          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
            {frontContent}
          </Typography>

          <Button
            variant={Button.VARIANTS.PRIMARY}
            icon={<HandLeft />}
            onClick={onChangeDisplayedSide}
          >
            <FormattedMessage {...messages.FLIP_CARD} />
          </Button>
        </div>

        <div
          css={styles.cardSide}
          className="backSide"
        >
          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
            {backContent}
          </Typography>

          <Button
            hue={Button.HUES.BLUE}
            variant={Button.VARIANTS.DANGER}
            icon={<HandLeft />}
            onClick={onChangeDisplayedSide}
            outline
          >
            <FormattedMessage {...messages.FLIP_CARD} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const FlashCard = memo(FlashCardBase);
