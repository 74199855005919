import { memo, useContext } from 'react';
import { Close } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';

import { Didaskill, Logo, Typography } from '@eversity/ui/design-system';

import AudioControls, {
  type AudioControlProps,
} from '../audio-controls/AudioControls';
import AudioDefaultThumbnail from '../audio-default-thumbnail/AudioDefaultThumbnail';
import { AudioPlayerContext } from '../contexts/AudioPlayer.context';
import * as styles from './AudioDrawer.styles';

type AudioDrawerProps = {
  title: string;
  subtitle?: string;
  onClickTitle?: () => void;
  thumbnailHref?: string;
} & Pick<AudioControlProps, 'isLoading'>;

const AudioDrawerBase = ({
  title,
  subtitle,
  onClickTitle = noop,
  thumbnailHref,
  isLoading = false,
}: AudioDrawerProps) => {
  const { onCloseDrawer, isDrawerOpen, isPortrait } =
    useContext(AudioPlayerContext);

  return (
    <div
      css={styles.mainContainer}
      className={cn({ isDrawerOpen })}
    >
      <div css={styles.drawerHeader}>
        <div css={styles.logoContainer}>
          <Logo
            height={40}
            css={styles.logo}
          />

          <Didaskill
            height={32}
            css={styles.brand}
            variant="primary"
          />
        </div>

        <Close
          onClick={onCloseDrawer}
          size={18}
        />
      </div>

      <div
        css={styles.drawerBody}
        className={cn({ isPortrait })}
      >
        <div css={styles.audioInfos}>
          {thumbnailHref ? (
            <img
              alt="Audio thumbnail"
              src={thumbnailHref}
              css={styles.audioThumbnail}
            />
          ) : (
            <AudioDefaultThumbnail />
          )}

          <button
            type="button"
            css={styles.audioTitles}
            onClick={onClickTitle}
          >
            <Typography variant={Typography.VARIANTS.BUTTON_SMALL_BOLD}>
              {title}
            </Typography>

            <Typography variant={Typography.VARIANTS.BUTTON_SMALL_REGULAR}>
              {subtitle}
            </Typography>
          </button>
        </div>

        <AudioControls isLoading={isLoading} />
      </div>
    </div>
  );
};

export default memo(AudioDrawerBase);
