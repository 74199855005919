import { type ChangeEvent, memo, useMemo } from 'react';
import ReactSlider from 'react-slider';
import { useTheme } from '@emotion/react';

import * as styles from './Slider.styles';

export type SliderProps = {
  min: number;
  max: number;
  value: number;
  onChange?: (value: number, event: ChangeEvent<HTMLInputElement>) => void;
  withThumb?: boolean;
};

export const SliderBase = ({
  min,
  max,
  value,
  onChange,
  withThumb = true,
}: SliderProps) => {
  const theme = useTheme();

  // Make it possible to send floats as min / max / value to the <ReactSlider /> library.
  // We're converting props to 100 based numbers
  const scaleValue = (
    valueToScale: number,
    minToScale: number,
    maxToScale: number,
  ) => {
    return ((valueToScale - minToScale) / (maxToScale - minToScale)) * 100;
  };

  const unscaleValue = (
    valueToScale: number,
    minToScale: number,
    maxToScale: number,
  ) => {
    return (valueToScale / 100) * (maxToScale - minToScale) + min;
  };

  const handleSliderChange = (
    sliderValue: number,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (onChange) {
      onChange(unscaleValue(sliderValue, min, max), event);
    }
  };

  const scaledDefaultValue = useMemo(
    () => scaleValue(value, min, max),
    [value, min, max],
  );

  return (
    <ReactSlider
      css={styles.slider(theme, value === min, value === max)}
      min={0}
      max={100}
      thumbClassName={withThumb ? 'thumb' : 'nothumb'}
      defaultValue={scaledDefaultValue}
      onChange={handleSliderChange}
    />
  );
};

export const Slider = memo(SliderBase);
