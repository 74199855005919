import { css, type Theme } from '@emotion/react';

export const stackContainer = css`
  position: relative;
`;

export const itemContainer = (
  theme: Theme,
  itemIndex: number,
  numberOfItems: number,
  gapBetweenItems: number,
) => css`
  z-index: ${numberOfItems - itemIndex};
  transform: translate(
    ${itemIndex * gapBetweenItems}px,
    ${itemIndex * gapBetweenItems}px
  );
  transition: ${theme.transitions.default('transform')};

  position: absolute;
  left: 0px;
  top: 0px;

  &.withShadowEffect {
    filter: brightness(${100 - itemIndex}%);
  }

  &.isHidden {
    display: none;
  }

  &.isFirstItem {
    position: relative;
  }
`;
