import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  type MemoExoticComponent,
  type ReactNode,
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { VARIANTS } from './constants';
import { StepperItem, type StepperItemProps } from './item/StepperItem';
import * as styles from './Stepper.styles';

export type StepperProps = {
  variant?: VARIANTS;
  children?: ReactNode | ReactNode[];
};

export const StepperBase = ({
  variant = VARIANTS.VERTICAL,
  children = undefined,
}: StepperProps) => {
  const childrenArray = Children.toArray(children).filter(
    isValidElement<StepperItemProps>,
  );

  return (
    <div
      css={styles.container}
      className={variant}
    >
      {childrenArray.map((child, index) => (
        <div
          key={JSON.stringify(child.props.href) || index}
          css={styles.step}
          className={cn({ variant, isHidden: child.props.isHidden })}
        >
          {cloneElement(child, {
            index: index + 1,
            variant,
            isLast: index === childrenArray.length - 1,
          })}
        </div>
      ))}
    </div>
  );
};

StepperBase.displayName = 'Stepper';

StepperBase.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  children: PropTypes.node,
};

export const Stepper: MemoExoticComponent<typeof StepperBase> & {
  Step?: typeof StepperItem;
  VARIANTS?: typeof VARIANTS;
} = memo(StepperBase);

Stepper.Step = StepperItem;
Stepper.VARIANTS = VARIANTS;
