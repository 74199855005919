import { type HTMLProps, memo } from 'react';
import { type Theme, useTheme } from '@emotion/react';

export type LogoProps = Omit<HTMLProps<SVGElement>, 'ref'> & {
  variant?: 'primary';
  type?: 'default' | 'ninja';
};

const getFillColorMapping = (theme: Theme, variant: LogoProps['variant']) => {
  switch (variant) {
    case 'primary':
    default:
      return theme.colors.primary[500];
  }
};

const LogoBase = ({
  variant = 'primary',
  type = 'default',
  ...props
}: LogoProps) => {
  const theme = useTheme();

  switch (type) {
    case 'ninja':
      return (
        <svg
          viewBox="0 0 122 122"
          {...props}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_58_21)">
            <path
              d="M83.9681 5.97234C84.4716 7.42477 83.7052 9.00438 82.2528 9.5079C80.8041 10.0101 79.2208 9.24507 78.7173 7.79264C78.215 6.34389 78.9838 4.75932 80.4325 4.25708C81.8849 3.75357 83.4658 4.52359 83.9681 5.97234Z"
              fill={`${getFillColorMapping(theme, variant)}`}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M81.6836 6.10282C81.6836 6.10282 76.854 3.66522 68.5996 2.54514C64.761 2.02497 60.1895 1.85032 55.0372 2.26038C53.226 2.44642 51.4871 2.68563 49.8165 3.00457C48.0395 3.33869 46.3423 3.74875 44.7173 4.1816C39.1624 5.66618 34.4277 7.41274 30.012 7.93291C26.5492 8.34297 23.3105 8.03543 20.0376 6.16357C19.6389 5.91297 19.1149 6.03068 18.8643 6.42935C18.6099 6.82802 18.7314 7.35199 19.1301 7.60638C22.646 10.0402 26.2758 10.7464 30.2246 10.5793C34.7922 10.3819 39.7965 8.97705 45.5715 7.71649C47.1586 7.37097 48.7989 7.03305 50.5075 6.71791C52.0908 6.42935 53.731 6.14458 55.4396 5.94714C60.3034 5.2713 64.6433 5.24472 68.3186 5.46114C76.2161 5.92816 81.004 7.66333 81.004 7.66333C81.4368 7.85317 81.938 7.65574 82.1241 7.22289C82.3139 6.79385 82.1165 6.28886 81.6836 6.10282Z"
              fill={`${getFillColorMapping(theme, variant)}`}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M80.3267 6.41391C80.3267 6.41391 77.9969 6.25445 74.7537 6.1899C74.0482 6.17471 73.3154 6.17851 72.5442 6.20129C72.063 6.21648 71.5762 6.23166 71.084 6.28102C69.8754 6.39873 68.6558 6.59616 67.4526 6.77841C65.4728 7.07837 63.6243 7.44287 62.0164 7.58335C61.5515 7.56057 61.1468 7.92127 61.1249 8.39209C61.1031 8.8629 61.464 9.26157 61.9344 9.28435C63.586 9.5957 65.522 9.88046 67.6003 10.002C68.8472 10.0779 70.1215 10.1121 71.3629 10.0513C71.8661 10.0247 72.3638 9.96399 72.8505 9.89945C73.6271 9.79313 74.3654 9.66404 75.0655 9.51596C78.2812 8.83632 80.5399 8.10352 80.5399 8.10352C81.0048 8.04657 81.3384 7.62132 81.2783 7.15431C81.2181 6.68729 80.7915 6.35696 80.3267 6.41391Z"
              fill={`${getFillColorMapping(theme, variant)}`}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.7628 16.8838C97.7023 17.3612 97.9833 18.6433 97.0785 19.1784C95.5577 20.0832 93.779 20.5991 91.8847 20.5991C86.2481 20.5991 81.678 16.029 81.678 10.3924C81.678 4.75579 86.2481 0.185669 91.8847 0.185669C94.8955 0.185669 97.6022 1.49087 99.4733 3.56995C100.278 4.46318 99.2885 5.68753 98.0834 5.68368C95.9196 5.68368 93.7058 6.49221 92.6855 8.96015C91.2802 12.3637 93.5595 15.259 96.7628 16.8838Z"
              fill={`${getFillColorMapping(theme, variant)}`}
            />
            <path
              d="M98.1143 10.7662C96.5011 10.4081 95.7503 9.47636 97.0401 8.61393C98.6533 7.53974 103.497 11.6594 98.1143 10.7662Z"
              fill={`${getFillColorMapping(theme, variant)}`}
            />
            <path
              d="M120.962 39.1967C107.699 46.77 99.6726 44.9725 90.9912 41.0444C90.9887 41.0433 90.9859 41.045 90.9859 41.0477V41.0477C90.9859 41.0504 90.9832 41.0521 90.9808 41.0511C88.7041 40.0278 86.238 39.7364 83.8478 40.3662C81.0362 41.1085 78.6219 43.0124 76.4405 45.8487C75.6983 46.8138 74.5928 47.4485 73.3774 47.5182C72.2317 47.584 71.0843 47.6705 69.9372 47.7836C67.2794 48.0461 64.6763 48.3967 62.2105 48.9131C61.173 49.1309 60.1711 49.4199 59.1989 49.7122C57.6284 50.1857 56.1501 50.6872 54.7722 51.2167V51.2167C54.1763 51.4453 53.6019 50.8148 53.8832 50.2419C58.3803 41.0806 60.6772 33.6001 61.321 28.1554C61.545 26.2608 60.7286 24.4383 59.3428 23.7396C51.4681 19.7567 42.1885 17.0685 30.1828 17.9038C28.7779 17.9988 28.4172 15.5042 29.7537 14.9157C56.4345 3.15708 73.0613 14.4584 88.1161 24.6911C98.6653 31.8614 108.443 38.507 120.366 36.3263C121.725 36.0795 122.219 38.4753 120.962 39.1967Z"
              fill={`${getFillColorMapping(theme, variant)}`}
            />
            <path
              d="M51.2412 58.4181C50.0687 58.5764 49.0369 59.2671 48.4347 60.2854C39.0046 76.2329 23.8225 95.9339 0.844335 118.062C-0.693399 119.546 0.722836 122.534 2.45801 121.46C28.4856 105.334 43.9275 90.0976 56.3812 77.2832C60.2844 72.6624 65.7747 70.7298 70.8625 72.4194C78.5284 74.9709 85.6817 82.9329 75.9883 97.0117C74.8151 98.7165 76.4781 101.101 77.9475 99.8176C106.541 74.8095 86.9687 60.5194 75.0681 54.0745C74.174 53.5904 73.1253 53.5112 72.1562 53.8189C71.8458 53.9174 71.5349 54.0153 71.2236 54.1121C70.9376 54.2011 70.6523 54.29 70.3678 54.3788C68.182 55.0605 66.0391 55.7288 63.941 56.276C62.9401 56.5364 61.9458 56.7282 60.976 56.9153C59.3975 57.2183 57.8929 57.4725 56.4642 57.6817C54.529 57.966 52.769 58.2118 51.2412 58.4181Z"
              fill={`${getFillColorMapping(theme, variant)}`}
            />
          </g>
          <defs>
            <clipPath id="clip0_58_21">
              <rect
                width="121.5"
                height="121.5"
                fill="white"
                transform="translate(0.1875 0.1875)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case 'default':
    default:
      return (
        <svg
          viewBox="0 0 25 25"
          {...props}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.9678 2.1C20.9678 3.2598 20.0276 4.2 18.8678 4.2C17.708 4.2 16.7678 3.2598 16.7678 2.1C16.7678 0.940202 17.708 0 18.8678 0C20.0276 0 20.9678 0.940202 20.9678 2.1ZM24.8511 8.02629C22.1214 9.58529 20.4699 9.21468 18.6829 8.40647L18.6828 8.40862C18.2139 8.19724 17.7061 8.13784 17.2138 8.26716C16.3231 8.50217 15.6262 9.30414 15 10.5C14.9194 10.6529 14.9678 10.8562 15.0998 10.925C17.4895 12.1644 22.1373 15.1323 16 20.5C15.6972 20.7642 15.3557 20.2733 15.5972 19.9223C17.5911 17.026 16.1198 15.3873 14.5422 14.8622C13.4952 14.5146 12.3654 14.9123 11.5628 15.8634C9 18.5 5.82296 21.6349 0.467253 24.9531C0.110496 25.1742 -0.18121 24.5591 0.135318 24.2539C8.95844 15.7572 12.195 8.9989 12.5792 5.75478C12.6247 5.36522 12.4571 4.98966 12.1722 4.84595C10.5514 4.02641 8.64223 3.47335 6.1721 3.64523C5.88308 3.66495 5.80866 3.15149 6.08322 3.03041C11.5734 0.611206 14.9947 2.93644 18.0924 5.04179C20.263 6.51707 22.2748 7.88439 24.7284 7.43566C25.0076 7.38498 25.1096 7.87805 24.8511 8.02629Z"
            fill={`${getFillColorMapping(theme, variant)}`}
          />
        </svg>
      );
  }
};

LogoBase.displayName = 'Logo';

export const Logo = memo(LogoBase);
