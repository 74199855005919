export enum PARSE_DURATION_BASE_UNITS {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  DAYS = 'DAYS',
}

const DAY_IN_HOURS = 24;
const HOUR_IN_MINUTES = 60;
const MINUTE_IN_SECONDS = 60;

export const DAY_IN_SECONDS =
  DAY_IN_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS;
const HOUR_IN_SECONDS = HOUR_IN_MINUTES * MINUTE_IN_SECONDS;
const DAY_IN_MINUTES = DAY_IN_HOURS * HOUR_IN_MINUTES;

/**
 * Parse a duration.
 *
 * @param value - Duration.
 * @param unit - Unit of duration param (parseDuration.BASE_UNITS enum).
 * @returns Parsed duration.
 */
export const parseDuration = (
  value: number,
  unit: PARSE_DURATION_BASE_UNITS,
) => {
  let valueForComputation = value;

  if (unit === PARSE_DURATION_BASE_UNITS.MINUTE) {
    valueForComputation = value * MINUTE_IN_SECONDS;
  } else if (unit === PARSE_DURATION_BASE_UNITS.DAYS) {
    valueForComputation = value * DAY_IN_SECONDS;
  }

  const days = Math.floor(valueForComputation / DAY_IN_SECONDS);
  const hours =
    Math.floor(valueForComputation / HOUR_IN_SECONDS) - days * DAY_IN_HOURS;
  const minutes =
    Math.floor(valueForComputation / MINUTE_IN_SECONDS) -
    (days * DAY_IN_MINUTES + hours * HOUR_IN_MINUTES);
  const seconds =
    valueForComputation -
    (days * DAY_IN_SECONDS +
      hours * HOUR_IN_SECONDS +
      minutes * MINUTE_IN_SECONDS);

  return {
    hours,
    minutes,
    days,
    seconds,
  };
};

parseDuration.BASE_UNITS = PARSE_DURATION_BASE_UNITS;

/**
 * Format time for audio display.
 *
 * @param timeInSeconds - Time in seconds to format.
 * @returns Formatted time.
 */
export const formatTimeForAudio = (timeInSeconds: number) => {
  const totalSeconds = Math.floor(timeInSeconds);
  const hours = Math.floor(totalSeconds / HOUR_IN_SECONDS);
  const minutes = Math.floor(
    (totalSeconds % HOUR_IN_SECONDS) / MINUTE_IN_SECONDS,
  );
  const remainingSeconds = totalSeconds % MINUTE_IN_SECONDS;

  return [
    hours ? `${hours}` : null,
    hours
      ? minutes.toString().padStart(2, '0')
      : minutes.toString().padStart(1, '0'),
    remainingSeconds.toString().padStart(2, '0'),
  ]
    .filter(Boolean)
    .join(':');
};
