/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom/client';

import App from './react/context';
import { getDefaultLocale, importLocale, setAppLocale } from './services/intl';
import { bootstrapSentry } from './services/sentry/bootstrap';

const initLocale = async () => {
  const locale = getDefaultLocale();
  await importLocale(locale);
  setAppLocale(locale);
};

(async function main() {
  await Promise.all([initLocale(), bootstrapSentry()]);

  ReactDOM.createRoot(document.getElementById('root')).render(<App />);
})();
