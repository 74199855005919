import { css } from '@emotion/react';

import { removeButtonAppearance } from '@eversity/ui/design-system';

export const mainContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
  height: 100%;
`;

export const soundAndTimeContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.isDrawerOpen {
    justify-content: space-evenly;
  }

  width: 100%;
  height: 100%;
`;

export const mainControlsContainer = css`
  display: flex;

  gap: 10px;
  justify-content: space-between;
`;

export const volumeControlsContainer = css`
  display: flex;

  align-items: center;
  justify-content: space-between;
`;

export const crossDrag = css`
  display: flex;
  flex-direction: column;

  height: 100%;
  justify-content: space-between;
`;

export const dragButton = css`
  padding: 7px;

  cursor: grab;
`;

export const muteButton = css`
  ${removeButtonAppearance}

  padding: 8px;
`;

export const sliderContainer = css`
  width: 148px;

  &.isDrawerOpen {
    width: 100%;
  }
`;
