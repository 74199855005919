import {
  memo,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from 'react';
import { type To } from 'react-router-dom';
import cn from 'classnames';
import { noop } from 'lodash';

import { Link } from '../../link/Link';
import { VARIANTS } from '../constants';
import StepperItemContent from './content/StepperItemContent';
import * as styles from './StepperItem.styles';

export type StepperItemProps = {
  index?: number;
  href?: To;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isLast?: boolean;
  isDone?: boolean;
  isCurrent?: boolean;
  isIncomplete?: boolean;
  isDisabled?: boolean;
  variant?: VARIANTS;
  icon?: ReactElement;
  children?: ReactNode;
  isLabelHidden?: boolean;
  isTagHidden?: boolean;
  isHidden?: boolean;
};

export const StepperItemBase = ({
  index = 0,
  href = undefined,
  onClick = noop,
  isLast = false,
  isDone = false,
  isCurrent = false,
  isIncomplete = false,
  isDisabled = false,
  variant = VARIANTS.VERTICAL,
  icon = undefined,
  children = undefined,
  isLabelHidden = false,
  isTagHidden = false,
  isHidden = false,
}: StepperItemProps) =>
  href ? (
    <Link
      to={href}
      isDisabled={isDisabled}
      css={(theme) => styles.container(theme, variant)}
      className={cn({ isDisabled, isHidden })}
    >
      <StepperItemContent
        variant={variant}
        index={index}
        isLast={isLast}
        isDone={isDone}
        isCurrent={isCurrent}
        isIncomplete={isIncomplete}
        isDisabled={isDisabled}
        icon={icon}
      >
        {children}
      </StepperItemContent>
    </Link>
  ) : (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      css={(theme) => styles.container(theme, variant)}
      className={cn({ isDisabled, isHidden })}
    >
      <StepperItemContent
        variant={variant}
        index={index}
        isLast={isLast}
        isDone={isDone}
        isCurrent={isCurrent}
        isIncomplete={isIncomplete}
        isDisabled={isDisabled}
        icon={icon}
        isLabelHidden={isLabelHidden}
        isTagHidden={isTagHidden}
      >
        {children}
      </StepperItemContent>
    </button>
  );

StepperItemBase.displayName = 'StepperItem';

export const StepperItem = memo(StepperItemBase);
