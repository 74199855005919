import superagent from 'superagent';

import { APP_ORIGIN_HEADER_KEY, APP_ORIGINS } from '@eversity/domain/constants';

import { LOCALSTORAGE_AUTH_TOKEN_KEY } from '../../domain/impersonate/constants';

const agent = superagent.agent();
const storedToken = localStorage.getItem(LOCALSTORAGE_AUTH_TOKEN_KEY);

agent.set(APP_ORIGIN_HEADER_KEY, APP_ORIGINS.WEB);

export const setAuthToken = (token: string) => {
  agent.set('Authorization', `Bearer ${token}`);
};

if (storedToken) {
  setAuthToken(storedToken);
}

export default agent;
