import { createContext, type ReactNode, useContext } from 'react';
import { type ResponseError } from 'superagent';

import { type StudentViewFull } from '@eversity/types/domain';
import { useMemoizedBundle } from '@eversity/ui/utils';

import { useUser } from '../hooks/queries/user';

type AuthContextValue = {
  user: StudentViewFull | undefined;
  isLoading: boolean;
  error?: Error | ResponseError;
};

const AuthContext = createContext<AuthContextValue>({
  user: null,
  isLoading: false,
  error: null,
});

type AuthProviderProps = {
  children?: ReactNode;
};

const AuthProvider = ({ children = null }: AuthProviderProps) => {
  const {
    data: user,
    isLoading,
    error,
  } = useUser({
    throwOnError: false,
  });

  const contextValue = useMemoizedBundle<AuthContextValue>({
    user,
    isLoading,
    error: error as Error,
  });

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
