import { css, type Theme } from '@emotion/react';

import { removeButtonAppearance } from '@eversity/ui/design-system';

export const mainContainer = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${removeButtonAppearance};

  @media (min-width: ${theme.breakpoints.small}) {
    cursor: default;
  }

  height: 100%;
  width: 100%;
`;

export const audioInfos = css`
  display: flex;
  align-items: center;

  gap: 10px;
  overflow: hidden;
`;

export const audioTitles = css`
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

export const title = css`
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const thumbnail = (theme: Theme) => css`
  @media (min-width: ${theme.breakpoints.small}) {
    width: 48px;
    height: 48px;
  }

  height: 37px;
  width: 37px;

  object-fit: cover;
  border-radius: 8px;
`;

export const audioTypo = css`
  display: block;
`;
