import { memo, type SVGProps } from 'react';
import { type Theme, useTheme } from '@emotion/react';

export type DidaskillProps = Omit<SVGProps<SVGElement>, 'ref'> & {
  variant?: 'primary';
};

const getFillColorMapping = (
  theme: Theme,
  variant: DidaskillProps['variant'],
) => {
  switch (variant) {
    case 'primary':
    default:
      return theme.colors.primary[500];
  }
};

const DidaSkillBase = ({ variant, ...props }: DidaskillProps) => {
  const theme = useTheme();
  return (
    <svg
      viewBox="0 0 464 90"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.74419 26.6279C1.74419 18.3333 5.34884 11.8217 12.5581 7.09302C19.7674 2.36434 28.062 0 37.4419 0C49.4574 0 60 3.44961 69.0698 10.3488C73.5659 13.8372 77.0543 18.4884 79.5349 24.3023C82.0155 30.0388 83.2558 36.3954 83.2558 43.3721C83.2558 57.093 79.3411 68.2558 71.5116 76.8605C63.3721 85.6202 53.1783 90 40.9302 90C38.2171 90 35.3488 89.6512 32.3256 88.9535C29.3023 88.2558 26.8992 87.3256 25.1163 86.1628C22.2481 88.3333 18.5659 89.4186 14.0698 89.4186C9.65116 89.4186 6.20155 88.1783 3.72093 85.6977C1.24031 83.2171 0 80.0388 0 76.1628C0 67.0155 6.93798 59.2248 20.814 52.7907C21.2016 49.5349 21.7442 45.155 22.4419 39.6512C23.2171 34.0698 23.7985 29.5736 24.186 26.1628C24.6512 22.7519 24.9612 20.5039 25.1163 19.4186C28.2946 17.4031 31.9767 16.3954 36.1628 16.3954C38.1008 16.3954 39.9612 16.5892 41.7442 16.9767L34.4186 66.6279C33.7209 71.0465 32.7519 74.7287 31.5116 77.6744C33.9147 78.2946 35.8527 78.6047 37.3256 78.6047C46.2403 78.6047 53.1395 75.2326 58.0233 68.4884C63.062 61.4341 65.5814 52.907 65.5814 42.907C65.5814 30.1163 60.969 20.9302 51.7442 15.3488C46.6279 12.2481 41.0078 10.6977 34.8837 10.6977C27.0543 10.6977 20.8527 12.8295 16.2791 17.093C13.6434 19.4961 12.3256 22.4031 12.3256 25.814C12.3256 27.5969 12.8682 29.3023 13.9535 30.9302C15.0388 32.4806 16.938 33.3721 19.6512 33.6047L18.4884 41.1628C12.8295 40.8527 8.60465 39.3023 5.81395 36.5116C3.10078 33.6434 1.74419 30.3488 1.74419 26.6279ZM8.60465 74.6512C8.60465 77.4419 9.92248 78.8372 12.5581 78.8372C13.6434 78.8372 14.7674 78.1395 15.9302 76.7442C17.1705 75.2713 18.0233 72.9845 18.4884 69.8837L19.5349 62.6744C12.2481 66.783 8.60465 70.7752 8.60465 74.6512Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M95.714 3.37209C97.497 1.51163 99.6675 0.581395 102.226 0.581395C104.784 0.581395 106.954 1.51163 108.737 3.37209C110.598 5.15504 111.528 7.32558 111.528 9.88373C111.528 12.4419 110.598 14.6124 108.737 16.3954C106.954 18.1783 104.784 19.0698 102.226 19.0698C99.6675 19.0698 97.497 18.1783 95.714 16.3954C93.9311 14.6124 93.0396 12.4419 93.0396 9.88373C93.0396 7.32558 93.9311 5.15504 95.714 3.37209ZM90.9466 37.4419C91.7218 36.0465 93.1946 35 95.3652 34.3023C97.5357 33.5271 99.6675 33.1395 101.761 33.1395C103.931 33.1395 105.83 33.4109 107.458 33.9535C104.435 57.2868 102.923 69.0698 102.923 69.3023C102.536 70.8527 102.342 72.1705 102.342 73.2558C102.342 74.2636 102.419 75.155 102.574 75.9302C102.885 77.9457 104.28 78.9535 106.761 78.9535C109.319 78.9535 111.644 77.3256 113.737 74.0698C115.908 70.7364 117.536 66.9767 118.621 62.7907C119.164 63.1008 120.016 64.031 121.179 65.5814C122.342 67.1318 123.04 68.2946 123.272 69.0698C121.722 75.2713 119.086 80.2713 115.365 84.0698C111.644 87.8682 107.187 89.7674 101.993 89.7674C96.7993 89.7674 92.9233 88.4884 90.3652 85.9302C87.8846 83.3721 86.6443 80.0775 86.6443 76.0465C86.6443 75.1938 86.683 74.3023 86.7605 73.3721L90.9466 37.4419Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M158.208 5.11628C158.75 3.79845 159.952 2.71318 161.812 1.86047C163.75 0.930234 166.037 0.465117 168.673 0.465117C171.386 0.465117 173.401 0.852714 174.719 1.62791C173.944 7.67442 172.704 17.1705 170.998 30.1163C167.432 57.1705 165.65 72.093 165.65 74.8837C165.65 77.5969 166.812 78.9535 169.138 78.9535C171.153 78.9535 173.246 77.7132 175.417 75.2326C177.588 72.6744 179.564 68.5271 181.347 62.7907C183.518 64.8837 185.029 67.1705 185.882 69.6512C183.091 78.876 178.712 84.845 172.743 87.5581C170.029 88.876 167.471 89.5349 165.068 89.5349C158.169 89.5349 153.557 87.0543 151.231 82.093C147.2 87.1318 142.045 89.6512 135.766 89.6512C129.487 89.6512 124.177 87.5194 119.836 83.2558C115.572 78.9923 113.44 73.3721 113.44 66.3953C113.44 56.2403 116.425 48.1395 122.394 42.093C128.363 36.0465 135.921 33.0233 145.068 33.0233C145.301 33.0233 145.533 33.0233 145.766 33.0233C148.944 33.0233 151.929 33.4884 154.719 34.4186L158.208 5.11628ZM146.812 42.093C141.851 42.093 137.898 43.9923 134.952 47.7907C132.084 51.5116 130.378 57.093 129.836 64.5349C129.758 65.2326 129.719 65.8915 129.719 66.5116C129.719 70.3101 130.611 73.3333 132.394 75.5814C134.254 77.8295 136.619 78.9535 139.487 78.9535C144.37 78.9535 148.053 75.5039 150.533 68.6047L153.557 43.6047C150.998 42.5969 148.75 42.093 146.812 42.093Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M229.237 89.5349C222.105 89.5349 217.376 87.0543 215.051 82.093C211.02 87.1318 205.826 89.6512 199.469 89.6512C193.19 89.6512 187.88 87.5194 183.539 83.2558C179.276 78.9923 177.144 73.3721 177.144 66.3953C177.144 56.2403 180.128 48.1395 186.097 42.093C192.066 36.0465 199.625 33.0233 208.772 33.0233C209.004 33.0233 209.237 33.0233 209.469 33.0233C213.345 33.0233 216.911 33.6822 220.167 35C222.26 33.6822 224.78 33.0233 227.725 33.0233C230.749 33.0233 232.919 33.4109 234.237 34.1861C233.539 39.9225 232.803 45.5814 232.028 51.1628C231.252 56.6667 230.71 60.5814 230.4 62.907C229.702 68.1783 229.353 71.9768 229.353 74.3023C229.353 77.4031 230.594 78.9535 233.074 78.9535C235.012 78.9535 237.066 77.6744 239.237 75.1163C241.407 72.4806 243.307 68.3721 244.935 62.7907C247.183 64.8062 248.733 67.0543 249.586 69.5349C247.338 76.8992 244.314 82.093 240.516 85.1163C236.718 88.062 232.958 89.5349 229.237 89.5349ZM210.516 42.093C205.555 42.093 201.601 43.9923 198.656 47.7907C195.787 51.5116 194.082 57.093 193.539 64.5349C193.462 65.2326 193.423 65.8915 193.423 66.5116C193.423 70.3101 194.314 73.3333 196.097 75.5814C197.958 77.8295 200.322 78.9535 203.19 78.9535C208.074 78.9535 211.756 75.5039 214.237 68.6047L217.028 43.4884C214.78 42.5581 212.609 42.093 210.516 42.093Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M285.964 30.3488C288.289 26.8605 289.452 23.6047 289.452 20.5814C289.452 17.5581 288.6 15.155 286.894 13.3721C285.189 11.5891 282.941 10.6977 280.15 10.6977C277.359 10.6977 275.111 11.3953 273.406 12.7907C271.7 14.1085 270.848 16.2403 270.848 19.186C270.848 22.0543 272.863 27.2481 276.894 34.7674C280.925 42.2868 283.483 47.1318 284.569 49.3023C287.514 55.1938 288.987 60.8915 288.987 66.3953C288.987 73.2946 286.468 78.9147 281.429 83.2558C276.468 87.5194 270.576 89.6512 263.755 89.6512C257.01 89.6512 251.7 87.5581 247.824 83.3721C243.948 79.1085 242.01 74.1473 242.01 68.4884C242.01 58.0233 248.483 49.3798 261.429 42.5581C256.158 32.1705 253.522 24.9225 253.522 20.814C253.522 15.0775 255.809 10.2326 260.382 6.27907C265.034 2.24807 270.693 0.232562 277.359 0.232562C284.103 0.232562 289.258 1.93799 292.824 5.34884C296.39 8.75969 298.173 13.1008 298.173 18.3721C298.173 21.8605 297.01 25.8527 294.685 30.3488H285.964ZM255.499 75.6977C257.592 77.9457 260.111 79.0698 263.057 79.0698C266.08 79.0698 268.406 78.2171 270.034 76.5116C271.739 74.7287 272.592 72.1318 272.592 68.7209C272.592 65.6202 271.39 61.8217 268.987 57.3256C268.677 56.7054 268.096 55.5814 267.243 53.9535C266.39 52.2481 265.77 51.0465 265.382 50.3488C256.7 54.845 252.359 60.5039 252.359 67.3256C252.359 70.814 253.406 73.6047 255.499 75.6977Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M350.648 47.093C350.648 55.155 345.842 61.3178 336.229 65.5814C336.462 66.9767 336.81 68.6434 337.276 70.5814C338.671 76.3178 340.997 79.2636 344.252 79.4186C344.485 79.4186 344.717 79.4186 344.95 79.4186C351.152 79.4186 355.764 73.9535 358.787 63.0233C360.415 64.4961 361.966 66.7054 363.438 69.6512C362.353 73.8372 360.803 77.3643 358.787 80.2326C356.849 83.1008 354.717 85.155 352.392 86.3953C348.283 88.5659 344.407 89.6512 340.764 89.6512C332.237 89.6512 326.268 84.8837 322.857 75.3488C321.539 71.5504 320.648 67.6744 320.183 63.7209C324.601 62.6357 328.09 60.8527 330.648 58.3721C333.206 55.814 334.485 53.1008 334.485 50.2326C334.485 45.2713 332.004 42.7907 327.043 42.7907C324.33 42.7907 322.004 43.8372 320.066 45.9302C318.128 47.9457 316.849 50.3876 316.229 53.2558C315.764 57.2093 314.989 63.4496 313.904 71.9767C312.896 80.4264 312.237 86.0853 311.927 88.9535H296.462C302.043 41.4341 305.415 12.2481 306.578 1.39535C308.206 0.775195 310.415 0.465117 313.206 0.465117C319.252 0.465117 322.276 2.24806 322.276 5.81395V6.51163C320.725 20.5426 319.291 31.2791 317.973 38.7209C322.237 34.7674 327.586 32.7907 334.02 32.7907C338.826 32.7907 342.779 34.031 345.88 36.5116C349.059 38.9922 350.648 42.5194 350.648 47.093Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M364.496 3.37209C366.279 1.51163 368.449 0.581395 371.007 0.581395C373.566 0.581395 375.736 1.51163 377.519 3.37209C379.38 5.15504 380.31 7.32558 380.31 9.88373C380.31 12.4419 379.38 14.6124 377.519 16.3954C375.736 18.1783 373.566 19.0698 371.007 19.0698C368.449 19.0698 366.279 18.1783 364.496 16.3954C362.713 14.6124 361.821 12.4419 361.821 9.88373C361.821 7.32558 362.713 5.15504 364.496 3.37209ZM359.728 37.4419C360.504 36.0465 361.976 35 364.147 34.3023C366.318 33.5271 368.449 33.1395 370.542 33.1395C372.713 33.1395 374.612 33.4109 376.24 33.9535C373.217 57.2868 371.705 69.0698 371.705 69.3023C371.318 70.8527 371.124 72.1705 371.124 73.2558C371.124 74.2636 371.201 75.155 371.356 75.9302C371.666 77.9457 373.062 78.9535 375.542 78.9535C378.1 78.9535 380.426 77.3256 382.519 74.0698C384.69 70.7364 386.318 66.9767 387.403 62.7907C387.945 63.1008 388.798 64.031 389.961 65.5814C391.124 67.1318 391.821 68.2946 392.054 69.0698C390.504 75.2713 387.868 80.2713 384.147 84.0698C380.426 87.8682 375.969 89.7674 370.775 89.7674C365.581 89.7674 361.705 88.4884 359.147 85.9302C356.666 83.3721 355.426 80.0775 355.426 76.0465C355.426 75.1938 355.465 74.3023 355.542 73.3721L359.728 37.4419Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M398.152 89.7674C393.424 89.7674 389.703 88.4496 386.989 85.814C384.276 83.1008 382.92 79.4186 382.92 74.7674C382.92 73.5271 385.013 55.1163 389.199 19.5349C390.284 10.3876 393.191 4.6124 397.92 2.2093C400.09 1.04651 402.687 0.465117 405.71 0.465117C415.943 0.465117 421.059 5.1938 421.059 14.6512C421.059 15.4264 421.02 16.2403 420.943 17.093C420.168 27.4806 413.656 38.062 401.408 48.8372C401.02 52.6357 400.594 56.3178 400.129 59.8837C399.199 67.8682 398.734 72.2868 398.734 73.1395C398.734 77.1705 400.478 79.1861 403.966 79.1861C407.765 79.1861 411.292 77.0155 414.548 72.6744C417.881 68.2558 420.439 62.6357 422.222 55.814C422.92 56.0465 423.889 56.7054 425.129 57.7907C426.369 58.7985 427.222 59.6899 427.687 60.4651C426.447 66.8992 423.656 73.062 419.315 78.9535C417.145 81.9768 414.393 84.4574 411.059 86.3953C407.261 88.6434 402.958 89.7674 398.152 89.7674ZM402.687 37.2093C409.819 29.3798 413.385 22.3643 413.385 16.1628C413.385 12.4419 411.989 10.5814 409.199 10.5814C407.416 10.7364 406.214 11.8992 405.594 14.0698C405.051 16.2403 404.509 19.7674 403.966 24.6512C403.501 29.5349 403.191 32.5581 403.036 33.7209L402.687 37.2093Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
      <path
        d="M433.695 89.7674C428.966 89.7674 425.245 88.4496 422.532 85.814C419.819 83.1008 418.462 79.4186 418.462 74.7674C418.462 73.5271 420.555 55.1163 424.741 19.5349C425.826 10.3876 428.733 4.6124 433.462 2.2093C435.633 1.04651 438.229 0.465117 441.253 0.465117C451.485 0.465117 456.602 5.1938 456.602 14.6512C456.602 15.4264 456.563 16.2403 456.485 17.093C455.71 27.4806 449.198 38.062 436.95 48.8372C436.563 52.6357 436.136 56.3178 435.671 59.8837C434.741 67.8682 434.276 72.2868 434.276 73.1395C434.276 77.1705 436.02 79.1861 439.509 79.1861C443.307 79.1861 446.834 77.0155 450.09 72.6744C453.423 68.2558 455.981 62.6357 457.764 55.814C458.462 56.0465 459.431 56.7054 460.671 57.7907C461.912 58.7985 462.764 59.6899 463.229 60.4651C461.989 66.8992 459.198 73.062 454.857 78.9535C452.687 81.9768 449.935 84.4574 446.602 86.3953C442.803 88.6434 438.501 89.7674 433.695 89.7674ZM438.229 37.2093C445.361 29.3798 448.927 22.3643 448.927 16.1628C448.927 12.4419 447.532 10.5814 444.741 10.5814C442.958 10.7364 441.757 11.8992 441.136 14.0698C440.594 16.2403 440.051 19.7674 439.509 24.6512C439.043 29.5349 438.733 32.5581 438.578 33.7209L438.229 37.2093Z"
        fill={`${getFillColorMapping(theme, variant)}`}
      />
    </svg>
  );
};

DidaSkillBase.displayName = 'Didaskill';

export const Didaskill = memo(DidaSkillBase);
