import { memo } from 'react';
import { useTheme } from '@emotion/react';
import { DegreeHat } from '@icon-park/react';

import * as styles from './AudioDefaultThumbnail.styles';

const AudioDefaultThumbnailBase = () => {
  const { colors } = useTheme();

  return (
    <div css={styles.container}>
      <DegreeHat
        size={25}
        fill={[colors.primary[200], colors.primary[50]]}
      />
    </div>
  );
};

export default memo(AudioDefaultThumbnailBase);
