import { Children, memo, type PropsWithChildren, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import cn from 'classnames';

import * as styles from './Stack.styles';

export type StackProps = {
  maxItemsToRender?: number;
  currentItemIndex?: number;
  withShadowEffect?: boolean;
  gapBetweenItems?: number;
} & PropsWithChildren;

export const StackBase = ({
  maxItemsToRender = 2,
  currentItemIndex = 0,
  withShadowEffect = true,
  gapBetweenItems = 5,
  children,
}: StackProps) => {
  const theme = useTheme();
  const childrenToArray = useMemo(() => Children.toArray(children), [children]);

  return (
    <div css={styles.stackContainer}>
      {childrenToArray.map((item, index) => (
        <div
          css={styles.itemContainer(
            theme,
            index - currentItemIndex,
            childrenToArray.length - currentItemIndex,
            gapBetweenItems,
          )}
          className={cn({
            withShadowEffect,
            isFirstItem: index - currentItemIndex === 0,
            isHidden:
              index < currentItemIndex ||
              index - currentItemIndex > maxItemsToRender,
          })}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export const Stack = memo(StackBase);
