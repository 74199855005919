export enum ICON_SHADOW_SIZES {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const ICON_SIZE_MAPPING = {
  [ICON_SHADOW_SIZES.EXTRA_SMALL]: 18,
  [ICON_SHADOW_SIZES.SMALL]: 24,
  [ICON_SHADOW_SIZES.MEDIUM]: 36,
  [ICON_SHADOW_SIZES.LARGE]: 42,
};
