import {
  USER_LESSON_PROGRESS_SEQUENCE_POSITION_TYPES,
  type USER_LESSON_PROGRESS_SEQUENCE_STATUSES,
} from '@eversity/domain/constants';
import {
  type CreateUserLessonProgressBody,
  type GetUserLessonProgressesQuery,
  type McqAnswer,
  type UserLessonProgress,
} from '@eversity/types/domain';

import { HttpRepository } from '../../httpRepository';

const e = encodeURIComponent;

export class UserLessonsProgressRepository extends HttpRepository {
  /**
   * Get user lesson progresses.
   *
   * @param query - Query.
   * @param query.courseId - Course id.
   * @param query.classId - Class id.
   * @param query.isCompleted - Filter by completion status.
   * @param query.sort - Sort.
   * @param query.limit - Limit.
   * @returns The user lesson progresses.
   */
  async getUserLessonProgresses(
    query: GetUserLessonProgressesQuery,
  ): Promise<UserLessonProgress[]> {
    const { body } = await this.http
      .get('/api/v1/users/users/me/lesson-progresses')
      .query(query);

    return body;
  }

  /**
   * Initialize a new user lesson progress for a lesson.
   *
   * @param params - Params.
   * @param params.courseId - Course id.
   * @param params.classId - Class id.
   * @param params.teachingUnitId - Teaching unit id.
   * @param params.lessonId - Lesson id.
   * @returns New user lesson progress.
   */
  async createUserLessonProgress(
    params: CreateUserLessonProgressBody,
  ): Promise<UserLessonProgress> {
    const { body } = await this.http
      .post('/api/v1/users/users/me/lesson-progresses')
      .send(params);

    return body;
  }

  /**
   * Set user progress current position.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Last sequence id visited, or null to reset.
   * @returns The updated user progress.
   */
  async setUserLessonCurrentPosition({
    progressId,
    sequenceVersionId,
    blockId,
    type = USER_LESSON_PROGRESS_SEQUENCE_POSITION_TYPES.READ,
  }: {
    progressId: string;
    sequenceVersionId?: string | null;
    type?: USER_LESSON_PROGRESS_SEQUENCE_POSITION_TYPES;
    blockId?: string;
  }): Promise<UserLessonProgress> {
    const { body } = await this.http
      .put(
        `/api/v1/users/users/me/lesson-progresses/${e(
          progressId,
        )}/positions/current`,
      )
      .send({
        type,
        sequenceVersionId,
        blockId,
      });

    return body;
  }

  /**
   * Set user sequence progress.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @param params.status - New status.
   * @returns The updated user progress.
   */
  async setUserLessonSequenceProgress({
    progressId,
    sequenceVersionId,
    status,
  }: {
    progressId: string;
    sequenceVersionId: string;
    status?: USER_LESSON_PROGRESS_SEQUENCE_STATUSES;
  }): Promise<UserLessonProgress> {
    const { body } = await this.http
      .patch(
        `/api/v1/users/users/me/lesson-progresses/${e(
          progressId,
        )}/sequences/${e(sequenceVersionId)}`,
      )
      .send({
        status,
      });

    return body;
  }

  /**
   * Init user sequence exercise attempt.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @param params.exercise - New exercise attempt.
   * @returns The updated user progress.
   */
  async initUserLessonSequenceExerciseAttempt({
    progressId,
    sequenceVersionId,
    exercise,
  }: {
    progressId: string;
    sequenceVersionId: string;
    exercise: {
      exerciseVersionId: string;
      exerciseAnswers: McqAnswer[];
    };
  }): Promise<UserLessonProgress> {
    const { body } = await this.http
      .post(
        `/api/v1/users/users/me/lesson-progresses/${e(
          progressId,
        )}/sequences/${e(sequenceVersionId)}/exercise/attempts`,
      )
      .send({
        exercise,
      });

    return body;
  }

  /**
   * Update user sequence exercise attempt.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @param params.attemptExerciseId - Id of the exercise attempt.
   * @param params.exercise - Exercise data.
   * @returns The updated user progress.
   */
  async updateUserLessonSequenceExerciseAttempt({
    progressId,
    sequenceVersionId,
    attemptExerciseId,
    exercise,
  }: {
    progressId: string;
    sequenceVersionId: string;
    attemptExerciseId: string;
    exercise: {
      exerciseVersionId: string;
      exerciseAnswers: McqAnswer[];
    };
  }): Promise<UserLessonProgress> {
    const { body } = await this.http
      .patch(
        `/api/v1/users/users/me/lesson-progresses/${e(
          progressId,
        )}/sequences/${e(sequenceVersionId)}/exercise/attempts/${e(attemptExerciseId)}`,
      )
      .send({
        exercise,
      });

    return body;
  }

  /**
   * Submit user sequence exercise attempt.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @param params.attemptExerciseId - Id of the exercise attempt.
   * @param params.exercise - Exercise data.
   * @returns The updated user progress.
   */
  async submitUserLessonSequenceExerciseAttempt({
    progressId,
    sequenceVersionId,
    attemptExerciseId,
    exercise,
  }: {
    progressId: string;
    sequenceVersionId: string;
    attemptExerciseId: string;
    exercise: {
      exerciseVersionId: string;
      exerciseAnswers: McqAnswer[];
    };
  }): Promise<UserLessonProgress> {
    const { body } = await this.http
      .post(
        `/api/v1/users/users/me/lesson-progresses/${e(
          progressId,
        )}/sequences/${e(sequenceVersionId)}/exercise/attempts/${e(attemptExerciseId)}/submit`,
      )
      .send({
        exercise,
      });

    return body;
  }

  /**
   * Ping user activity on a sequence.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @returns The updated user progress.
   */
  async setUserLessonSequenceLastActiveTimestamp({
    progressId,
    sequenceVersionId,
  }: {
    progressId: string;
    sequenceVersionId: string;
  }): Promise<UserLessonProgress> {
    const { body } = await this.http.put(
      `/api/v1/users/users/me/lesson-progresses/${e(progressId)}/sequences/${e(
        sequenceVersionId,
      )}/pings/active`,
    );

    return body;
  }

  /**
   * Stop pinging user activity on a sequence.
   *
   * @param params - Params.
   * @param params.progressId - User lesson progress id.
   * @param params.sequenceVersionId - Sequence id.
   * @returns The updated user progress.
   */
  async unsetUserLessonSequenceLastActiveTimestamp({
    progressId,
    sequenceVersionId,
  }: {
    progressId: string;
    sequenceVersionId: string;
  }): Promise<UserLessonProgress> {
    const { body } = await this.http.delete(
      `/api/v1/users/users/me/lesson-progresses/${e(progressId)}/sequences/${e(
        sequenceVersionId,
      )}/pings/active`,
    );

    return body;
  }
}
