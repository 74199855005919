import { AI_INTERACTION_TYPES } from '../ai-interactions';

export enum SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum GMAIL_LABEL_IDS {
  UNREAD = 'UNREAD',
}

export enum CRON_JOB_TYPES {
  UPDATE_KPIS = 'UPDATE_KPIS',
  CORRECTORS_REPORTING = 'CORRECTORS_REPORTING',
  VIRTUAL_CLASSROOMS_ATTENDEES = 'VIRTUAL_CLASSROOMS_ATTENDEES',
  DB_BIGQUERY_EXTRACT = 'DB_BIGQUERY_EXTRACT',
  CLOSE_STUDENT_COURSE_ACCESS_NOW = 'CLOSE_STUDENT_COURSE_ACCESS_NOW',
  CLOSE_STUDENT_COURSE_ACCESS_SOON = 'CLOSE_STUDENT_COURSE_ACCESS_SOON',
  OPEN_STUDENT_COURSE_ACCESS = 'OPEN_STUDENT_COURSE_ACCESS',
}

export enum BIGQUERY_DATASETS {
  MONGO_COLLECTIONS = 'MONGO_COLLECTIONS',
  MONGO_VIEWS = 'MONGO_VIEWS',
}

export const DATASET_TABLES = {
  [BIGQUERY_DATASETS.MONGO_COLLECTIONS]: {
    AI_INTERACTIONS: 'AI_INTERACTIONS',
    ASSIGNMENTS: 'ASSIGNMENTS',
    CORRECTION_FEES: 'CORRECTION_FEES',
    COURSES: 'COURSES',
    EMPLOYEES: 'EMPLOYEES',
    EVENTS: 'EVENTS',
    LESSONS: 'LESSONS',
    NEWS: 'NEWS',
    NOTES: 'NOTES',
    FEEDBACK_CAMPAIGNS: 'FEEDBACK_CAMPAIGNS',
    SESSIONS: 'SESSIONS',
    SKILLS: 'SKILLS',
    STUDENTS: 'STUDENTS',
    SUBMISSIONS: 'SUBMISSIONS',
    STUDENT_HISTORY_KPIS: 'STUDENT_HISTORY_KPIS',
    TRACKING_EVENTS: 'TRACKING_EVENTS',
    USER_TRANSCRIPT_ARCHIVES: 'USER_TRANSCRIPT_ARCHIVES',
  },
  [BIGQUERY_DATASETS.MONGO_VIEWS]: {},
} as const satisfies Record<BIGQUERY_DATASETS, { [key: string]: string }>;

export enum CONTACT_CATEGORIES {
  EDUCATION = 'EDUCATION',
  COURSE = 'COURSE',
  INTERNSHIPS = 'INTERNSHIPS',
  EXAMS = 'EXAMS',
  DISABILITY = 'DISABILITY',
  ACCOUNTING = 'ACCOUNTING',
  WORK_STUDY = 'WORK_STUDY', // Alternance in french.
  WORK_STUDY_HELP = 'WORK_STUDY_HELP',
  APPOINTMENT_COACH = 'APPOINTMENT_COACH',
  APPOINTMENT_TEACHER = 'APPOINTMENT_TEACHER',
}

export enum TOPICS {
  COURSE_INFORMATION_CHANGED = 'COURSE_INFORMATION_CHANGED',
  LESSON_PUBLISHED_AUDIO = 'LESSON_PUBLISHED_AUDIO',
  LESSON_PUBLISHED_CHANGELOG = 'LESSON_PUBLISHED_CHANGELOG',
  NEWS_PUBLISHED = 'NEWS_PUBLISHED',
  SUBMISSION_GRADED = 'SUBMISSION_GRADED',
  SUBMISSION_COMMENTED = 'SUBMISSION_COMMENTED',
  STUDENT_COURSE_ADDED = 'STUDENT_COURSE_ADDED',
  STUDENT_COURSE_TRANSCRIPT = 'STUDENT_COURSE_TRANSCRIPT',
  STUDENT_COURSE_END_ACCESS = 'STUDENT_COURSE_END_ACCESS',
  DATA_CHANGE_STREAMS = 'DATA_CHANGE_STREAMS',
  SYNC_VIRTUAL_CLASSROOM = 'SYNC_VIRTUAL_CLASSROOM',
}

export enum APP_ORIGINS {
  WEB = 'WEB',
  BACKOFFICE = 'BACKOFFICE',
  MOBILE = 'MOBILE',
}

export const APP_ORIGIN_HEADER_KEY = 'APP-ORIGIN';

export const AI_PROMPT_TYPES = {
  [AI_INTERACTION_TYPES.SUMMARIZE_SEQUENCE]: 'SUMMARIZE_SEQUENCE',
  [AI_INTERACTION_TYPES.GENERATE_QUESTIONS]: 'GENERATE_QUESTIONS',
  [AI_INTERACTION_TYPES.SEARCH_LESSON]: 'SEARCH_LESSON',
  [AI_INTERACTION_TYPES.GENERATE_TRUE_FALSE_QUESTION]:
    'GENERATE_TRUE_FALSE_QUESTION',
} as const;

export enum DEAD_LETTER_STATUS {
  UNRESOLVED = 'unresolved',
  REPLAYED = 'replayed',
  IGNORED = 'ignored',
}
