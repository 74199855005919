import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { ICON_SHADOW_SIZES, ICON_SIZE_MAPPING } from './constants';

export const iconContainer = css`
  position: relative;
`;

export const iconPlaceholder = (color: string) => css`
  &.${ICON_SHADOW_SIZES.EXTRA_SMALL} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.EXTRA_SMALL]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.EXTRA_SMALL]}px;
  }

  &.${ICON_SHADOW_SIZES.SMALL} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.SMALL]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.SMALL]}px;
  }

  &.${ICON_SHADOW_SIZES.MEDIUM} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.MEDIUM]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.MEDIUM]}px;
  }

  &.${ICON_SHADOW_SIZES.LARGE} {
    width: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.LARGE]}px;
    height: ${ICON_SIZE_MAPPING[ICON_SHADOW_SIZES.LARGE]}px;
  }

  border-radius: 32px;
  box-shadow: 0 0 0 4px ${new TinyColor(color).setAlpha(0.15).toRgbString()};
`;

export const customIconContainer = (color: string) => (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: ${theme.colors.gray[0]};

  border: 2px solid ${color};
`;

export const icon = css`
  position: absolute;
  top: -2px;
  left: -2px;
`;
